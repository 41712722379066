import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import userSelectors from '../store/user/userSelectors';
import { UserRoleEnum } from '../store/user/userTypes';
import { useEffect } from 'react';

export default function ShouldPasswordUpdateProvider({ children }) {
  const navigate = useNavigate();
  const user = useAppSelector(userSelectors.getCurrentUser);

  useEffect(() => {
    if (
      user.role !== UserRoleEnum.SUPER_ADMIN &&
      user.shouldPromptPasswordUpdate
    ) {
      navigate('/private-update-password');
    }
  }, [navigate, user.role, user.shouldPromptPasswordUpdate]);

  return children;
}
