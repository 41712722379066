// Components
import PrivateUpdatePasswordForm from '../../Components/PrivateUpdatePasswordForm/PrivateUpdatePasswordForm';

// Styles
import s from './styles.module.scss';

export const PrivateUpdatePasswordPage = () => {
  return (
    <div className={s.formWrapper}>
      <PrivateUpdatePasswordForm />
    </div>
  );
};
