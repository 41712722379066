//react
import React, { FC, useEffect, useRef, useState } from 'react';
import { Notify } from 'notiflix';

//icons
import ErrorWarningLineIcon from 'remixicon-react/ErrorWarningLineIcon';

//router
import { useNavigate } from 'react-router-dom';

//styles
import styles from './styles.module.scss';

// mui components
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

// Formik
import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  FormikValues,
} from 'formik';
import PostFormSchema from './PostFormSchema';

// custom components
import PostImagePreview from '../PostCard/PostImagePreview/PostImagePreview';
import ErrorMessage from '../common/ErrorMessage';
import ConfirmDeclineButtons from '../PagesForm/ConfirmDeclineButtons/ConfirmDeclineButtons';
import CustomSwitchInput from '../CustomSwitchInput/CustomSwitchInput';
import QuillEditor from '../QuillEditor/QuillEditor';

// types
import { ICroppedViewPostImage, PostType } from '../../interfaces/post';
import {
  ImageLabelTheme,
  postLabelTheme,
  PostTypeEnum,
  postTypeProperty,
} from '../../interfaces/requestParams';

// helpers
import dayjs from 'dayjs';
import CropPostImageModal from '../Modals/CropPostImageModal/CropPostImageModal';
import {
  AspectOfCropImageEnum,
  maxPostFileSizeToLoadMb,
  postFileFormats,
} from '../../Utils/constants';
import { notifyOptions } from '../../Utils';

const initialCroppedImagePosition: ICroppedViewPostImage = {
  collectionView: {
    zoom: 1,
    croppedArea: {
      x: 0,
      y: 33,
      width: 100,
      height: 100 / AspectOfCropImageEnum.COLLECTION,
    },
  },
  detailsPageView: {
    zoom: 1,
    croppedArea: {
      x: 0,
      y: 33,
      width: 100,
      height: 100 / AspectOfCropImageEnum.DETAILS_PAGE,
    },
  },
};

export interface PostInitialValuesType {
  image: File | null | string;
  imageLabel: string;
  imageLabelTheme: ImageLabelTheme;
  title: string;
  type: string;
  date: string;
  content: string;
  isActive: boolean;
}

const initialValues: PostInitialValuesType = {
  image: null,
  imageLabel: '',
  imageLabelTheme: '' as ImageLabelTheme,
  title: '',
  type: '',
  date: '',
  content: '',
  isActive: false,
};

interface PostFormProps {
  isEdit: boolean;
  post?: PostType | null;
  onSubmit: (postData: FormData) => void;
  isLoading: boolean;
}

const PostForm: FC<PostFormProps> = ({ isEdit, post, onSubmit, isLoading }) => {
  const [newPost, setNewPost] = useState<PostInitialValuesType>(initialValues);
  const [image, setImage] = useState<string | null>(null);
  const [imageName, setImageName] = useState<string>('');

  const [openCropPostImageModal, setOpenCropPostImageModal] =
    useState<boolean>(false);
  const [croppedImagePosition, setCroppedImagePosition] =
    useState<ICroppedViewPostImage>(
      post ? post.mainImageCropData : initialCroppedImagePosition
    );
  const [savedCroppedImagePosition, setSavedCroppedImagePosition] =
    useState<ICroppedViewPostImage>(
      post ? post.mainImageCropData : initialCroppedImagePosition
    );

  useEffect(() => {
    if (post?.mainImageCropData) {
      setCroppedImagePosition(post.mainImageCropData);
      setSavedCroppedImagePosition(post.mainImageCropData);
    }
  }, [post?.mainImageCropData]);

  const formikRef = useRef<FormikProps<PostInitialValuesType> | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const handleFileClick = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const handleSubmit = (postSubmit: PostInitialValuesType) => {
    if (isEdit && post) {
      const preparePostData = new FormData();

      if (postSubmit.title !== post.title) {
        preparePostData.append('title', postSubmit.title);
      }
      if (postSubmit.type !== post.type) {
        postSubmit.type &&
          preparePostData.append(
            'type',
            postSubmit.type === 'Articles' ? PostTypeEnum.BLOG : postSubmit.type
          );
      }
      if (postSubmit.imageLabel !== post.mainImageLabel) {
        preparePostData.append('mainImageLabel', postSubmit.imageLabel);
      }
      if (postSubmit.imageLabelTheme !== post.mainImageLabelTheme) {
        preparePostData.append(
          'mainImageLabelTheme',
          postSubmit.imageLabelTheme
        );
      }
      if (postSubmit.isActive !== post.isActive) {
        preparePostData.append('isActive', postSubmit.isActive.toString());
      }
      if (postSubmit.content && postSubmit.content !== post.content) {
        preparePostData.append('content', postSubmit.content);
      }

      const editDate = new Date(postSubmit.date);
      const isoEditDate = postSubmit.date ? editDate.toISOString() : false;
      if (post.publishDate) {
        if (isoEditDate !== post.publishDate && isoEditDate) {
          preparePostData.append('publishDate', isoEditDate);
        }

        if (isoEditDate !== post.publishDate && !isoEditDate) {
          const date = new Date();
          date.setMinutes(date.getMinutes() + 1);
          const isoDate = date.toISOString();

          preparePostData.append('publishDate', isoDate);
        }
      } else {
        if (isoEditDate) {
          preparePostData.append('publishDate', isoEditDate);
        }
      }

      if (post.mainImage && !postSubmit.image) {
        preparePostData.append('mainImage', '');
      }

      if (
        post.mainImage !== postSubmit?.image &&
        postSubmit.image instanceof File &&
        postSubmit.image !== null
      ) {
        preparePostData.append('mainImageFile', postSubmit.image);
      }

      if (savedCroppedImagePosition) {
        // Collection VIEW
        preparePostData.append(
          'mainImageCropData[collectionView][zoom]',
          savedCroppedImagePosition.collectionView.zoom.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][x]',
          savedCroppedImagePosition.collectionView.croppedArea.x.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][y]',
          savedCroppedImagePosition.collectionView.croppedArea.y.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][width]',
          savedCroppedImagePosition.collectionView.croppedArea.width.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][height]',
          savedCroppedImagePosition.collectionView.croppedArea.height.toString()
        );

        // DetailsPage VIEW
        preparePostData.append(
          'mainImageCropData[detailsPageView][zoom]',
          savedCroppedImagePosition.detailsPageView.zoom.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][x]',
          savedCroppedImagePosition.detailsPageView.croppedArea.x.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][y]',
          savedCroppedImagePosition.detailsPageView.croppedArea.y.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][width]',
          savedCroppedImagePosition.detailsPageView.croppedArea.width.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][height]',
          savedCroppedImagePosition.detailsPageView.croppedArea.height.toString()
        );
      }

      onSubmit(preparePostData);
    }

    if (!isEdit) {
      const preparePostData = new FormData();
      preparePostData.append('title', postSubmit.title);
      preparePostData.append(
        'type',
        postSubmit.type === 'Articles' ? PostTypeEnum.BLOG : postSubmit.type
      );
      preparePostData.append('isActive', postSubmit.isActive.toString());
      if (postSubmit.content) {
        preparePostData.append('content', postSubmit.content);
      }
      if (postSubmit.imageLabel) {
        preparePostData.append('mainImageLabel', postSubmit.imageLabel);
      }
      if (postSubmit.imageLabelTheme) {
        preparePostData.append(
          'mainImageLabelTheme',
          postSubmit.imageLabelTheme
        );
      }
      if (postSubmit.date) {
        const date = new Date(postSubmit.date);
        const isoDate = date.toISOString();
        preparePostData.append('publishDate', isoDate);
      }
      if (
        postSubmit.image &&
        postSubmit.image instanceof File &&
        postSubmit.image !== null
      ) {
        preparePostData.append('mainImageFile', postSubmit.image);
      }

      if (savedCroppedImagePosition) {
        // Collection VIEW
        preparePostData.append(
          'mainImageCropData[collectionView][zoom]',
          savedCroppedImagePosition.collectionView.zoom.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][x]',
          savedCroppedImagePosition.collectionView.croppedArea.x.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][y]',
          savedCroppedImagePosition.collectionView.croppedArea.y.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][width]',
          savedCroppedImagePosition.collectionView.croppedArea.width.toString()
        );
        preparePostData.append(
          'mainImageCropData[collectionView][croppedArea][height]',
          savedCroppedImagePosition.collectionView.croppedArea.height.toString()
        );

        // DetailsPage VIEW
        preparePostData.append(
          'mainImageCropData[detailsPageView][zoom]',
          savedCroppedImagePosition.detailsPageView.zoom.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][x]',
          savedCroppedImagePosition.detailsPageView.croppedArea.x.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][y]',
          savedCroppedImagePosition.detailsPageView.croppedArea.y.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][width]',
          savedCroppedImagePosition.detailsPageView.croppedArea.width.toString()
        );
        preparePostData.append(
          'mainImageCropData[detailsPageView][croppedArea][height]',
          savedCroppedImagePosition.detailsPageView.croppedArea.height.toString()
        );
      }

      onSubmit(preparePostData);
    }
  };

  const handleAutocompleteChange = (
    field: FieldInputProps<string>,
    value: any,
    inputName: string
  ) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
    setNewPost({ ...newPost, [`${inputName}`]: value });
  };

  useEffect(() => {
    if (post && isEdit) {
      setNewPost({
        title: post.title,
        image: post.mainImage || null,
        imageLabel: post.mainImageLabel,
        imageLabelTheme: post.mainImageLabelTheme,
        type: post.type === PostTypeEnum.BLOG ? 'Articles' : post.type,
        date: post.publishDate || '',
        content: post.content || '',
        isActive: post.isActive,
      });
      if (formikRef.current) {
        formikRef.current.setFieldValue('title', post.title);
        formikRef.current.setFieldValue('image', post.mainImage);
        formikRef.current.setFieldValue('imageLabel', post.mainImageLabel);
        formikRef.current.setFieldValue(
          'imageLabelTheme',
          post.mainImageLabelTheme
        );
        formikRef.current.setFieldValue(
          'type',
          post.type === PostTypeEnum.BLOG ? 'Articles' : post.type
        );
        formikRef.current.setFieldValue('date', post.publishDate || '');
        formikRef.current.setFieldValue('content', post.content || '');
        formikRef.current.setFieldValue('isActive', post.isActive);
      }
      post.mainImage && setImage(post.mainImage);
    }
  }, [post, isEdit]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={PostFormSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({
          errors,
          touched,
          setFieldValue,
        }: {
          errors: FormikErrors<FormikValues>;
          touched: FormikTouched<FormikValues>;
          setFieldValue: any;
        }) => (
          <Form>
            <Box display="flex" flexDirection="column" gap="40px">
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box position="relative">
                  <InputLabel className={styles.label}>
                    Main Image <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Box className={styles.infoMessage} mb="10px">
                    <ErrorWarningLineIcon className={styles.warningIcon} />
                    <Typography className={styles.infoMessageText}>
                      For optimal display quality, please upload high-resolution
                      horizontal images. This ensures the best visual impact
                      across various devices and screen orientations.
                    </Typography>
                  </Box>

                  <Box position="relative">
                    {image ? (
                      <PostImagePreview
                        imageSrc={image}
                        imageName={imageName}
                        imageLabel={newPost.imageLabel}
                        imageLabelTheme={newPost.imageLabelTheme}
                        handleDelete={() => {
                          setNewPost({
                            ...newPost,
                            image: null,
                          });
                          setImage(null);
                          setFieldValue('image', null);
                          setImageName('');
                          setCroppedImagePosition(initialCroppedImagePosition);
                          setSavedCroppedImagePosition(
                            initialCroppedImagePosition
                          );
                          if (inputFileRef.current) {
                            inputFileRef.current.value = '';
                          }
                        }}
                        handleEdit={() => {
                          setOpenCropPostImageModal(true);
                        }}
                        croppedImagePosition={savedCroppedImagePosition}
                      />
                    ) : (
                      <Button
                        variant={'contained'}
                        onClick={handleFileClick}
                        className={styles.fileAddButton}
                      >
                        Add
                      </Button>
                    )}
                    <Field value={newPost.image} name="image">
                      {({ field }: { field: FieldInputProps<File> }) => (
                        <input
                          type="file"
                          accept="image/*"
                          id="image"
                          name="imageInput"
                          ref={inputFileRef}
                          style={{ display: 'none' }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file = e.target.files?.[0];
                            field.onChange({
                              target: {
                                name: field.name,
                                value: file,
                              },
                            });
                            if (file) {
                              // Validate file size
                              if (file.size > maxPostFileSizeToLoadMb) {
                                Notify.failure(
                                  `File size exceeds the limit of 10MB. File size: ${
                                    file.size / (1024 * 1024)
                                  }MB`,
                                  notifyOptions
                                );

                                if (inputFileRef.current) {
                                  inputFileRef.current.value = '';
                                }
                                return; // Stop further processing
                              }

                              // Validate file format
                              if (!postFileFormats.image.includes(file.type)) {
                                Notify.failure(
                                  `Unsupported file format: ${
                                    file.type
                                  }. Allowed formats are: ${postFileFormats.image.join(
                                    ', '
                                  )}`,
                                  notifyOptions
                                );

                                if (inputFileRef.current) {
                                  inputFileRef.current.value = '';
                                }

                                return; // Stop further processing
                              }

                              setImageName(file.name);
                              setNewPost({ ...newPost, image: file });
                              setImage(URL.createObjectURL(file));
                            }
                            setOpenCropPostImageModal(true);
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage name="image" />
                  </Box>
                </Box>
                <Box marginTop="2px">
                  <CustomSwitchInput
                    checked={newPost.isActive}
                    onChange={param => {
                      setNewPost({ ...newPost, isActive: param });
                      setFieldValue('isActive', param);
                    }}
                    label="Active"
                  />
                </Box>
              </Box>

              <Box display="flex" alignItems="center" gap="15px">
                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Label <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Field
                    as={OutlinedInput}
                    name="imageLabel"
                    fullWidth
                    value={newPost.imageLabel}
                    className={styles.input}
                    sx={{ height: { xs: '48px', md: '40px', xl: '48px' } }}
                    onInput={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => setNewPost({ ...newPost, imageLabel: e.target.value })}
                  />

                  <ErrorMessage name="imageLabel" />
                </Box>

                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Label Theme <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Field name="imageLabelTheme" value={newPost.imageLabelTheme}>
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <Autocomplete
                        fullWidth
                        value={newPost.imageLabelTheme}
                        className={styles.select}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: '48px',
                            paddingLeft: '14px',
                          },
                          '& .MuiAutocomplete-endAdornment': {
                            zIndex: 1,
                          },
                        }}
                        disableClearable
                        options={postLabelTheme}
                        renderOption={(props, option: ImageLabelTheme) => (
                          <Typography {...props} key={option}>
                            {option}
                          </Typography>
                        )}
                        renderInput={params => <TextField {...params} />}
                        onInputChange={(_, newValue) => {
                          handleAutocompleteChange(
                            field,
                            newValue as unknown as ImageLabelTheme,
                            'imageLabelTheme'
                          );
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="imageLabelTheme" />
                </Box>
              </Box>

              <Box display="flex" alignItems="center" gap="15px">
                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Title <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Field
                    as={OutlinedInput}
                    name="title"
                    fullWidth
                    value={newPost.title}
                    className={styles.input}
                    sx={{ height: { xs: '48px', md: '40px', xl: '48px' } }}
                    onInput={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => setNewPost({ ...newPost, title: e.target.value })}
                  />

                  <ErrorMessage name="title" />
                </Box>
                <Box position="relative" width="100%">
                  <InputLabel className={styles.label}>
                    Type <span className={styles.labelRequired}>*</span>
                  </InputLabel>

                  <Field name="type" value={newPost.type}>
                    {({ field }: { field: FieldInputProps<string> }) => (
                      <Autocomplete
                        fullWidth
                        value={newPost.type}
                        className={styles.select}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: '48px',
                            paddingLeft: '14px',
                          },
                          '& .MuiAutocomplete-endAdornment': {
                            zIndex: 1,
                          },
                        }}
                        disableClearable
                        options={postTypeProperty}
                        renderOption={(props, option) => (
                          <Typography {...props} key={option}>
                            {option}
                          </Typography>
                        )}
                        renderInput={params => <TextField {...params} />}
                        onInputChange={(_, newValue) => {
                          handleAutocompleteChange(field, newValue, 'type');
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="type" />
                </Box>
              </Box>

              <Box display="flex" alignItems="center" gap="15px">
                <Box position="relative">
                  <InputLabel className={styles.label}>Publish Date</InputLabel>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={{
                      fieldMeridiemPlaceholder: () => '',
                    }}
                  >
                    <DemoContainer
                      components={['DateTimePicker']}
                      sx={{ overflow: 'unset', padding: '0px' }}
                    >
                      <DateTimePicker
                        name="date"
                        minDateTime={dayjs(new Date())}
                        value={post?.publishDate ? dayjs(post.publishDate) : ''}
                        closeOnSelect={false}
                        onChange={newValue => {
                          setNewPost({ ...newPost, date: newValue as string });
                          setFieldValue('date', newValue);
                        }}
                        defaultValue={
                          post?.publishDate ? dayjs(post.publishDate) : ''
                        }
                        className={styles.input}
                        sx={{
                          '& .MuiOutlinedInput-input': {
                            height: '48px',
                            padding: '0 14px',
                          },
                          '& .MuiIconButton-root': {
                            zIndex: '1',
                            color: '#7A8699',
                          },
                          '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#DFE2E6 !important',
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <ErrorMessage name="date" />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" position="relative">
                <InputLabel className={styles.label}>Content</InputLabel>

                <QuillEditor
                  value={newPost.content}
                  setFieldValue={newValue => {
                    setNewPost({ ...newPost, content: newValue });
                    setFieldValue('content', newValue);
                  }}
                  placeholder=""
                />

                <ErrorMessage name="content" />
              </Box>
            </Box>
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <ConfirmDeclineButtons
                handleCancel={() => navigate('/posts')}
                saveButtonText={isEdit ? 'Save' : 'Create'}
                isLoading={isLoading}
              />
            </Box>
          </Form>
        )}
      </Formik>
      {image && (
        <CropPostImageModal
          open={openCropPostImageModal}
          onClose={() => {
            setCroppedImagePosition(savedCroppedImagePosition);
            setOpenCropPostImageModal(false);
          }}
          onSave={() => {
            setSavedCroppedImagePosition(croppedImagePosition);
            setOpenCropPostImageModal(false);
          }}
          imageUrl={image}
          croppedImagePosition={croppedImagePosition}
          setCroppedImagePosition={setCroppedImagePosition}
        />
      )}
    </>
  );
};

export default PostForm;
