// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Environment
import environment from '../../config';

// Types
import { RootState } from '../store';
import {
  ICreateUserRequest,
  ICreateUserResponse,
  IGetAllUsersRequest,
  IGetAllUsersResponse,
  IGetUserByIdResponse,
  IUpdatePasswordData,
  IUpdatePasswordResponse,
  IUpdateUserByIdData,
  IUpdateUserByIdResponse,
} from './userTypes';
import { ISimpleMessageResponse } from '../../interfaces/http';

export const userApi = createApi({
  reducerPath: 'userApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/users`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('Content-Type', 'application/json');

      headers.set('x-api-key', environment.API_KEY);

      return headers;
    },
  }),

  endpoints: builder => ({
    // CREATE USER
    createUser: builder.mutation<ICreateUserResponse, ICreateUserRequest>({
      query: data => {
        return {
          url: '/',
          method: 'POST',
          body: data,
        };
      },
    }),

    // GET ALL USERS
    getAllUsers: builder.query<IGetAllUsersResponse, IGetAllUsersRequest>({
      query: params => ({
        url: '',
        params,
      }),
    }),

    // GET USER BY ID
    getUserById: builder.query<IGetUserByIdResponse, string>({
      query: userId => ({
        url: `/${userId}`,
      }),
    }),

    // DELETE USER BY ID
    deleteUserById: builder.mutation<ISimpleMessageResponse, string>({
      query: userId => {
        return {
          url: `/${userId}`,
          method: 'DELETE',
        };
      },
    }),

    // UPDATE USER BY ID
    updateUserById: builder.mutation<
      IUpdateUserByIdResponse,
      IUpdateUserByIdData
    >({
      query: data => ({
        url: `/${data.userId}`,
        method: 'PATCH',
        body: data.body,
      }),
    }),

    // UPDATE USER PASSWORD
    updatePassword: builder.mutation<
      IUpdatePasswordResponse,
      IUpdatePasswordData
    >({
      query: ({ password, confirmPassword }) => ({
        url: `updatePassword`,
        method: 'PATCH',
        body: { password, confirmPassword },
      }),
    }),
  }),
});

export const {
  useCreateUserMutation,
  useLazyGetUserByIdQuery,
  useGetUserByIdQuery,
  useLazyGetAllUsersQuery,
  useDeleteUserByIdMutation,
  useUpdatePasswordMutation,
  useUpdateUserByIdMutation,
} = userApi;
