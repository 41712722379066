// React
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Mui
import {
  TextField,
  Typography,
  Box,
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Icon
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';

// Styles
import s from './styles.module.scss';

//redux
import {
  useUpdatePasswordMutation,
  useUpdateUserByIdMutation,
} from '../../store/user/userApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCurrentUser } from '../../store/user/userSlice';
import userSelectors from '../../store/user/userSelectors';
import { UserRoleEnum } from '../../store/user/userTypes';

// Notification
import { showErrorNotify } from '../helpers/showErrorNotify';
import { showSuccessNotify } from '../helpers/showSuccessNotify';

const PrivateUpdatePasswordForm = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(userSelectors.getCurrentUser);

  useEffect(() => {
    if (
      !user.shouldPromptPasswordUpdate ||
      user.role === UserRoleEnum.SUPER_ADMIN
    ) {
      navigate('/');
    }
  }, [navigate, user.shouldPromptPasswordUpdate, user.role]);

  // RTK queries - update password
  const [
    updatePasswordRequest,
    {
      data: dataUpdatePassword,
      isSuccess: isSuccessUpdatePassword,
      isError: isErrorUpdatePassword,
      error: errorUpdatePassword,
      isLoading: isLoadingUpdatePassword,
    },
  ] = useUpdatePasswordMutation();

  useEffect(() => {
    if (dataUpdatePassword && isSuccessUpdatePassword) {
      showSuccessNotify(dataUpdatePassword.message);
      dispatch(setCurrentUser(dataUpdatePassword.data.user));
      setFormData({
        password: '',
        confirmPassword: '',
      });
    }

    showErrorNotify(isErrorUpdatePassword, errorUpdatePassword);
  }, [
    dataUpdatePassword,
    isSuccessUpdatePassword,
    errorUpdatePassword,
    isErrorUpdatePassword,
  ]);

  // RTK queries - update user
  const [
    updateUserRequest,
    {
      data: dataUpdateUser,
      isSuccess: isSuccessUpdateUser,
      isError: isErrorUpdateUser,
      error: errorUpdateUser,
      isLoading: isLoadingUpdateUser,
    },
  ] = useUpdateUserByIdMutation();

  useEffect(() => {
    if (dataUpdateUser && isSuccessUpdateUser) {
      dispatch(setCurrentUser(dataUpdateUser.data.updatedUser));
      setFormData({
        password: '',
        confirmPassword: '',
      });
    }

    showErrorNotify(isErrorUpdateUser, errorUpdateUser);
  }, [dataUpdateUser, isSuccessUpdateUser, errorUpdateUser, isErrorUpdateUser]);

  // Handlers
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updatePasswordRequest({ ...formData });
  };

  const handleClickShowPassword = () => setIsShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setIsShowConfirmPassword(show => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <form className={s.form} onSubmit={e => handleSubmit(e)}>
        <Typography className={s.formTitle} variant={'h1'}>
          Welcome to CMS!
        </Typography>

        <Typography className={s.formDesc}>
          Your account was created recently, so we recommend update your
          password. If you do not want to change it, you can cancel this action
          and use your current password.
        </Typography>

        <Box sx={{ marginBottom: '30px' }}>
          <Typography className={s.label}>New Password</Typography>

          <TextField
            className={s.input}
            variant="outlined"
            type={isShowPassword ? 'text' : 'password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: 'var(--neutral-black)' }}
                  >
                    {isShowPassword ? <EyeOffLineIcon /> : <EyeLineIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography className={s.label}>Confirm Password</Typography>

          <TextField
            className={s.input}
            variant="outlined"
            type={isShowConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    sx={{ color: 'var(--neutral-black)' }}
                  >
                    {isShowConfirmPassword ? (
                      <EyeOffLineIcon />
                    ) : (
                      <EyeLineIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={s.buttonsWrapper}>
          <Button
            className={s.button}
            variant="outlined"
            color="primary"
            fullWidth
            disableElevation
            disabled={isLoadingUpdateUser}
            onClick={() => {
              updateUserRequest({
                userId: user._id,
                body: { shouldPromptPasswordUpdate: false },
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            startIcon={
              isLoadingUpdatePassword && <CircularProgress size={20} />
            }
            loadingPosition={isLoadingUpdatePassword ? 'start' : undefined}
            className={s.button}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={
              isLoadingUpdatePassword ||
              !formData.password ||
              !formData.confirmPassword
            }
          >
            Update Password
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default PrivateUpdatePasswordForm;
