import React, { FC } from 'react';

//mui components
import {
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
  Box,
} from '@mui/material';

//styles
import styles from './styles.module.scss';

// remixicon
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

// Components
import CropperImage from '../../CropperImage/CropperImage';
import { AspectOfCropImageEnum } from '../../../Utils/constants';
import { ICroppedViewPostImage } from '../../../interfaces/post';

interface CropPostImageModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  imageUrl: string;
  croppedImagePosition: ICroppedViewPostImage;
  setCroppedImagePosition: (
    croppedImagePosition: ICroppedViewPostImage
  ) => void;
}

const CropPostImageModal: FC<CropPostImageModalProps> = ({
  open,
  onClose,
  onSave,
  imageUrl,
  croppedImagePosition,
  setCroppedImagePosition,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Grid className={styles.modal}>
        <IconButton className={styles.buttonClose} onClick={onClose}>
          <CloseLineIcon />
        </IconButton>
        <Typography className={styles.modalTitle} variant="h5">
          Please select the image view of post for two options
        </Typography>
        <Box sx={{ width: '100%', marginBottom: '30px' }}>
          <Typography className={styles.modalDesc}>
            In collection view:
          </Typography>
          <Box className={styles.cropperWrapper}>
            <CropperImage
              imageUrl={imageUrl}
              aspect={AspectOfCropImageEnum.COLLECTION}
              setCroppedImagePosition={data => {
                setCroppedImagePosition({
                  ...croppedImagePosition,
                  collectionView: data,
                });
              }}
              initialData={croppedImagePosition.collectionView}
            />
          </Box>
        </Box>
        <Typography className={styles.modalDesc}>
          In details page view:
        </Typography>
        <Box className={styles.cropperWrapper}>
          <CropperImage
            imageUrl={imageUrl}
            aspect={AspectOfCropImageEnum.DETAILS_PAGE}
            setCroppedImagePosition={data => {
              setCroppedImagePosition({
                ...croppedImagePosition,
                detailsPageView: data,
              });
            }}
            initialData={croppedImagePosition.detailsPageView}
          />
        </Box>

        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={'10px'}
          width={'100%'}
        >
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            className={`${styles.button} ${styles.buttonCancel}`}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            onClick={onSave}
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CropPostImageModal;
