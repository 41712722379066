import React from 'react';
import { ICroppedArea } from '../../interfaces/post';

interface ICroppedImageViewProps {
  imageUrl: string;
  zoom?: number;
  croppedArea?: ICroppedArea;
}

const CroppedImageView = ({
  imageUrl,
  zoom = 1,
  croppedArea = { x: 0, y: 33, width: 100, height: 100 },
}: ICroppedImageViewProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <img
        src={imageUrl}
        alt="Cropped"
        style={{
          position: 'absolute',
          transform: `translate(-${croppedArea.x}%, -${croppedArea.y}%)`,
          width:
            croppedArea.height === 100
              ? `calc((200% - ${croppedArea.width}%) * ${zoom})`
              : `calc(100% * ${zoom})`,
        }}
      />
    </div>
  );
};

export default CroppedImageView;
