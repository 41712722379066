//react
import React from 'react';
import { FC, useEffect, useState } from 'react';

//styles
import styles from '../index.module.scss';

//mui components
import { Box, Typography, IconButton } from '@mui/material';

//remixicon
import More2Line from 'remixicon-react/More2LineIcon';

//custom components
import PostCardMenu from '../../PostCardMenu';
import { ImageLabelTheme } from '../../../interfaces/requestParams';
import CroppedImageView from '../../CroppedImageView/CroppedImageView';
import { ICroppedViewPostImage } from '../../../interfaces/post';

interface PostImagePreviewProps {
  imageSrc: string;
  imageName: string;
  imageLabel: string;
  imageLabelTheme: string;
  handleDelete: () => void;
  handleEdit: () => void;
  croppedImagePosition: ICroppedViewPostImage;
}
const PostImagePreview: FC<PostImagePreviewProps> = ({
  imageSrc,
  imageName,
  handleDelete,
  handleEdit,
  imageLabel,
  imageLabelTheme,
  croppedImagePosition,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickSubmenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);

  return (
    <Box
      className={styles.postDetailsContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box style={{ margin: '0 auto 6px auto' }}>
        <Box position="relative">
          <Box className={styles.imagesWrapper}>
            <Box>
              <Typography className={styles.imageViewDesc}>
                In collection view:
              </Typography>
              <Box className={styles.postImageDetails}>
                <CroppedImageView
                  imageUrl={imageSrc}
                  zoom={croppedImagePosition.collectionView?.zoom}
                  croppedArea={croppedImagePosition.collectionView?.croppedArea}
                />
              </Box>
            </Box>
            <Box>
              <Typography className={styles.imageViewDesc}>
                In details page view:
              </Typography>

              <Box className={styles.postImageDetailsView}>
                <CroppedImageView
                  imageUrl={imageSrc}
                  zoom={croppedImagePosition.detailsPageView?.zoom}
                  croppedArea={
                    croppedImagePosition.detailsPageView?.croppedArea
                  }
                />
              </Box>
            </Box>
          </Box>

          {imageLabel && (
            <Box
              className={styles.postLabelContainer}
              style={{
                backgroundColor:
                  imageLabelTheme === ImageLabelTheme.DARK
                    ? 'rgba(0, 0, 0, 0.35)'
                    : 'rgba(255, 255, 255, 0.35)',
              }}
            >
              <Typography className={styles.postLabelText}>
                {imageLabel}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {imageName && (
        <Typography
          className={styles.postTitle}
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {imageName}
        </Typography>
      )}
      <IconButton
        className={styles.postMoreButton}
        onClick={handleClickSubmenu}
      >
        <More2Line />
      </IconButton>
      <PostCardMenu
        anchorEl={anchorEl}
        handleClose={handleCloseMenu}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
    </Box>
  );
};

export default PostImagePreview;
