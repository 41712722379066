// react
import React, { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import { ICroppedImagePosition } from '../../interfaces/post';

interface ICropperImageProps {
  imageUrl: string;
  aspect?: number;
  initialData?: ICroppedImagePosition | null;
  setCroppedImagePosition: (data: ICroppedImagePosition) => void;
}

const CropperImage = ({
  imageUrl,
  aspect = 1,
  setCroppedImagePosition,
  initialData,
}: ICropperImageProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(initialData?.zoom ? initialData.zoom : 1);

  const onCropComplete = (croppedArea: Area) => {
    console.log('croppedArea', croppedArea);
    setCroppedImagePosition({ zoom, croppedArea });
  };

  return (
    <Cropper
      image={imageUrl}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      initialCroppedAreaPercentages={
        initialData?.croppedArea
          ? initialData.croppedArea
          : { x: 0, y: 33, width: 100, height: 100 / aspect }
      }
      objectFit="contain"
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  );
};

export default CropperImage;
